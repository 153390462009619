import validatorCommon from '../../services/helpers/validator.common';
import i18n from '../../services/lang';

const validators = {
	validateRequired(value, required, model) {
		if (model.IsTestTask) {
			let res = validatorCommon.checkEmpty(value, required);
			if (res) return res;
		}

		return null;
	},
	
	validateRequiredEmail(value, required, model) {
		if (model.IsTestTask) {
			let res = validatorCommon.checkEmpty(value, required);
			if (res) return res;

			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
			if (!re.test(value)) {
				return [i18n.t('amistake!')];
			}
		}

		return null;
	}
};

export default validators;
