var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: false,
          tooltipMsg: "manualofdetailc",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n\t\t" + _vm._s(_vm.$t("newtaskinemaile")) + "\n\t\t"
                  ),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("id")) +
                        " " +
                        _vm._s(_vm.model.PetaskId) +
                        " "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "formActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("createatesttask"),
                    },
                    on: {
                      handleClick: function ($event) {
                        _vm.model.IsTestTask = true
                        _vm.saveModel({ closeOnSuccess: true })
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("createarealtask"),
                    },
                    on: {
                      handleClick: function ($event) {
                        _vm.model.IsTestTask = false
                        _vm.saveModel({ closeOnSuccess: true })
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-primary",
                      icon: "undo",
                      title: _vm.$t("cancel2168"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.redirectToTable()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3197151016
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }