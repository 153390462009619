<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="false"
		tooltipMsg="manualofdetailc"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openModal()"
	>
		<template #header>
			{{ $t('newtaskinemaile') }}
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.PetaskId }} </label>
		</template>

		<template #formActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('createatesttask')"
				@handleClick="
					model.IsTestTask = true;
					saveModel({ closeOnSuccess: true });
				"
			></megau-button>

			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('createarealtask')"
				@handleClick="
					model.IsTestTask = false;
					saveModel({ closeOnSuccess: true });
				"
			></megau-button>

			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('cancel2168')"
				@handleClick="redirectToTable()"
			></megau-button>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import { model, fields } from './emailer.form-data';
import http from '@/services/helpers/http';
import apiDic from '@/services/api/dictionary.api';
import serviceEnums from '@/services/service/enums.service';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'PortalEmailTask',
			routeParam: 'PetaskId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {},

	created() {},

	methods: {
		async loadResources() {
			const [accCompanies, portalEmails] = await Promise.all([
				http.get('AccountingCompany/GetUsedList').then((res) => res.data),
				apiDic.getPortalEmailList().then((res) => res.data),
			]);
			const countries = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: {
					values: countries,
					accountingCompanies: accCompanies,
					onChanged: (model, newVal, oldVal, field) => {
						const accCompany = field.accountingCompanies.find((x) => x.CountryId === newVal);
						this.updateFormModel({ SenderEmail: accCompany ? accCompany.AcompanyEmail : '' });
					},
				},
				RecipientType: {
					values: serviceEnums.getEnumForVFG('emailerRecipientType').sort((v1, v2) => (v1.name > v2.name ? 1 : -1)),
				},
				Subscription: {
					values: serviceEnums.getEnumForVFG('emailerSubscription'),
				},
				EmailTemplateId: {
					values: portalEmails,
				},
			});
		},
	},
};
</script>
