import vueFormGenerator from 'vue-form-generator';
import validator from './emailer.validators';

export const model = {
	PetaskId: 0,
	PetaskName: '',
	PetaskIsConfirmed: false,
	RecipientType: null,
	Subscription: null,
	SenderEmail: null,
	SenderName: '',
	CountryId: 1,
	IsTestTask: false,
	EmailTemplateId: null,
	TestTaskCount: 0,
	TestTaskEmail: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				accountingCompanies: [],
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'RecipientType',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'to2958',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'Subscription',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'newtaskinemaile2957',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'SenderName',
				required: true,
				min: 1,
				i18n: {
					label: 'sendersname',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'SenderEmail',
				required: true,
				min: 1,
				i18n: {
					label: 'sendersemail',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'EmailTemplateId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
				},
				i18n: {
					label: 'emailname2976',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TestTaskCount',
				validator: validator.validateRequired,
				i18n: {
					label: 'numberofemailsa',
				},
				styleClasses: 'eighther-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'TestTaskEmail',
				min: 1,
				validator: validator.validateRequiredEmail,
				i18n: {
					label: 'emailrecipienta',
				},
				styleClasses: 'half-width',
			},
		],
	},
];
